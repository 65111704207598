












































import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/storageTerms/viewTitle";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { cloneDeep } from "lodash";
import { IDictionaryElement } from "@monorepo/catalog/src/views/StorageTerms/types/IDictionaryElement";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";

export default defineComponent({
  name: "Manuals",
  data() {
    return {
      viewTitle,
      viewUniqKey,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("storageTermsView", ["data", "isTableLoading"]),
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_CREATE);
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_MODIFY);
    },
    isShowDeleteBtn(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_DELETE);
    },
    localData: {
      get(): IDictionaryElement[] {
        return this.$store.getters["storageTermsView/localData"];
      },
      set(value: IDictionaryElement[]) {
        this.$store.commit("storageTermsView/setLocalData", {
          data: value,
        });
      },
    },
  },
  methods: {
    addNewManual() {
      this.localData = [
        ...this.localData,
        {
          key: `${Math.random()}-key`,
          value: "",
        },
      ];
    },
    addNewValue(value: string, index: number) {
      const manuals = cloneDeep(this.localData);
      (manuals[index] as { value: string }).value = value;
      this.localData = manuals;
    },
    deleteManual(item: IDictionaryElement) {
      let manuals = cloneDeep(this.localData);
      const fieldId = item.id ? "id" : "key";
      manuals = manuals.filter((localItem) => localItem[fieldId] !== item[fieldId]);
      this.localData = manuals;
    },
  },
});
