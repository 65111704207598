import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { cloneDeep } from "lodash";
import { IDictionaryElement } from "@monorepo/catalog/src/views/StorageTerms/types/IDictionaryElement";
import { getQuery } from "@monorepo/utils/src/api/utils";
import axios from "axios";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";

interface ITableState {
  data: IDictionaryElement[];
  localData: IDictionaryElement[];
  infiniteId: string;
  isTableLoading: boolean;
}

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    infiniteId: new Date().toString(),
    data: [],
    localData: [],
    isTableLoading: false,
  }),
  mutations: {
    ...baseMutations,
    addData(
      state: ITableState,
      payload: {
        data: IDictionaryElement[];
      }
    ) {
      state.data = cloneDeep(payload.data);
      state.localData = cloneDeep(payload.data);
    },
    resetAllData(state: ITableState) {
      state.localData = cloneDeep(state.data);
    },
    setAllData(state: ITableState) {
      state.data = cloneDeep(state.localData);
    },
    setLocalData(state: ITableState, payload: { data: IDictionaryElement[] }) {
      state.localData = cloneDeep(payload.data);
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getManualList({ commit, state }) {
      commit("setTableLoading", true);
      const { data } = await getQuery<IDictionaryElement[]>(QUERY_PATH.GET_STORAGE_TERMS).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [] });
        return { data: state.data || [] };
      }
      return { data: null };
    },
    async updateDictionaryItems(info, items: IDictionaryElement[]) {
      try {
        await axios.put(getFullPath(`${QUERY_PATH.GET_STORAGE_TERMS}/list`), items);
        return true;
      } catch (e) {
        console.log(e);
        showNotification("Ошибка сохранения значения справочника: " + e.response?.data?.message ?? "");
        return false;
      }
    },
    async createDictionaryItem(info, item: IDictionaryElement) {
      try {
        const { key, ...rest } = item;
        await axios.post(getFullPath(`${QUERY_PATH.GET_STORAGE_TERMS}`), rest);
        return true;
      } catch (e) {
        console.log(e);
        showNotification("Ошибка сохранения значения справочника: " + e.response?.data?.message ?? "");
        return false;
      }
    },
    async deleteDictionaryItem(info, id: string) {
      try {
        await axios.delete(getFullPath(`${QUERY_PATH.GET_STORAGE_TERMS}/${id}`));
        return true;
      } catch (e) {
        showNotification("Ошибка удаления значения справочника: " + e.response?.data?.message ?? "");
        return false;
      }
    },
  },
  getters: {
    ...baseGetters,
    data: (state: ITableState) => state.data ?? [],
    localData: (state: ITableState) => state.localData ?? [],
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
