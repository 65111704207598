



































import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import Manuals from "@monorepo/catalog/src/views/StorageTerms/components/Manuals.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { defineComponent } from "@vue/composition-api";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { breadcrumbs } from "@monorepo/catalog/src/views/StorageTerms/constants/breadcrumbs";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/storageTerms/viewTitle";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { mapActions, mapGetters, mapMutations, Module } from "vuex";
import { module as storageTermsView } from "@monorepo/catalog/src/store/modules/storageTermsView";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IDictionaryElement } from "@monorepo/catalog/src/views/StorageTerms/types/IDictionaryElement";
import { v4 as uuid } from "uuid";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";

export default defineComponent({
  name: "StorageTermsView",
  components: {
    Breadcrumbs,
    Manuals,
    Footer,
  },
  data() {
    return {
      breadcrumbs,
      isSaveLoading: false,
      viewTitle,
      key: uuid(),
    };
  },
  computed: {
    ...mapGetters("storageTermsView", ["data", "localData", "dictionariesItems", "localDictionariesItems", "isTableLoading"]),
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_MODIFY);
    },
    addedItems() {
      return (this.localData as IDictionaryElement[]).filter((item) => !item.id && item.value);
    },
    dataObj(): Record<string, string> {
      return (this.data as IDictionaryElement[]).reduce((result: Record<string, string>, item) => {
        result[item.id || ""] = item.value;
        return result;
      }, {});
    },
    deletedItemsIds(): string[] {
      const dataIds: string[] = (this.data as IDictionaryElement[]).map((item) => item.id).filter((id) => id) as string[];
      const localDataIds = (this.localData as IDictionaryElement[]).filter((item) => !!item.id).map((item) => item.id);
      return dataIds.filter((id: string) => !localDataIds.includes(id));
    },
    changedItems() {
      return (this.localData as IDictionaryElement[]).filter((item) => !!item.id && item.value !== (this.dataObj as Record<string, string>)[item.id]);
    },
    isShowFooter(): boolean {
      return this.isCanEdit && (!!this.addedItems.length || !!this.deletedItemsIds.length || !!this.changedItems.length);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.DOC_KIND_CREATE);
    },
    isEmptyIndexes(): number[] {
      return (this.localData as IDictionaryElement[])
        .map((item, index) => (!!item.id && !item.value ? index : null))
        .filter((key: number | null) => key !== null) as number[];
    },
  },
  methods: {
    ...mapMutations("storageTermsView", ["resetAllData"]),
    ...mapActions("storageTermsView", ["updateDictionaryItems", "getManualList", "createDictionaryItem", "deleteDictionaryItem"]),
    clearAllData() {
      this.resetAllData();
    },
    async saveData() {
      try {
        this.isSaveLoading = true;
        if (this.isEmptyIndexes.length) {
          showNotification(`Заполните пустые значения на строчках: ${this.isEmptyIndexes.map((index) => index + 1).join(", ")}`);
          return;
        }
        const deletePromises = this.deletedItemsIds.map((id: string) => this.deleteDictionaryItem(id));
        const postPromises = this.addedItems.map((item) => this.createDictionaryItem(item));
        const updatePromises = this.changedItems.length ? [this.updateDictionaryItems(this.changedItems)] : [];
        const promises = deletePromises.concat(postPromises).concat(updatePromises);
        const isSaveDict = await Promise.all(promises);
        if (isSaveDict) {
          await this.refreshView();
        }
      } finally {
        this.isSaveLoading = false;
      }
    },
    async refreshView() {
      this.key = uuid();
      await this.getManualList();
    },
  },
  setup(props, { root }) {
    useInitTableStoreModule(root, "storageTermsView", storageTermsView as Module<unknown, unknown>);
    useOpenHistoryMode(root, "storageTermsView");
    if (!root.$store.getters["storageTermsView/data"].length) {
      root.$store.dispatch("storageTermsView/getManualList").catch(console.error);
    }
  },
});
